@import url('https://fonts.googleapis.com/css2?family=Poppins');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&display=swap');
@import './variables.scss'; 

body {
  margin: 0;
  padding: 0;
  font-family: $primary-font,Helvetica, serif;
}
.container{
  max-width: 1280px;
}
.text{
  font-family: $lato, Helvetica, sans-serif;
}
/*spectre override*/
img{
  display: block;
}
.modal.active .modal-overlay, .modal:target .modal-overlay{
  background: rgba(0,0,0,0.8);
}
.btn.btn-link{
  color: $brown;
  font-family: $lato;
  text-transform: uppercase;
  &:visited,
  &:hover,
  &:active,
  &:focus{
    color: $brown;
    box-shadow: none;
  }
.logo-main{
  width: 122px;
}
}
.loading.loading-lg{
  margin-top: 50%;
}
.main-btn,
.dim-btn{
  background: $brown;
  padding: 10px 15px;
  display: inline-block;
  color: #fff;
  text-align: center;
  cursor: pointer;
  &:visited,
  &:active{
    text-decoration: none;
    color: #fff;
  }
  &:hover{
    background: darken($brown, 10%);
    color: #fff;
    text-decoration: none;
  }
}
.dim-btn{
  background: $earth;
  color: $brown;
  &:hover{
    background: darken($earth, 10%);
    color: $brown;
    text-decoration: none;
  }
}
input[type="text"]:focus,
select:active,select:focus,select{
  box-shadow: none;
  border-color: $green !important;
}
.action{
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #fff !important;
  border: 1px solid #000;
  label{
    font-size: 10px;
    font-family: $lato;
    font-weight: 700;
    text-decoration: underline;
    display: block;
    text-align: center;
  }
}
.para{
  font-size: 13px;
  line-height: 21px;
}
/*CkEditor*/
.ck.ck-editor__main>.ck-editor__editable{
  min-height: 300px;
}
/*CK Editor*/
/*spectre override*/
.menu-bar a{
  position: relative;
  margin: 0 20px;
  padding-left: 0;
  padding-right: 0;
  &::after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: $green;
    display: none;
  }
  &:hover::after,
  &:focus::after{
    display: block;
  }
}
.menu-bar .nav{
  flex-direction: row;
  li .nav{
    position: absolute;
    flex-direction: column;
    li{
      a{
        margin-left: 0;
      }
    }
  }
}
.menu{
  box-shadow: none;
  padding: 15px;
  padding-top: 0;
}
.logo h1{
  font-size: 24px;
  padding: 20px;
}
/*cart*/
.action_summary{
  margin-left: 20px;
}
table.summary{
  padding: 20px;
  padding-top: 5px;
  width: 100%;
}
.cart{
  background-color: $cream;
  padding: 20px 0;
  h4,p.para{
    padding: 10px;
  }
  .cart-table {
    padding: 10px;
    .cart_table_row{
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: darken($earth,10%);
      padding: 0 10px;
      &.odd{
        background: none;
      }
      .details{
        display: flex;
        align-items: center;
        input{
          width: 50px;
        }
        button{
          border: none;
          background: $brown;
          color: #fff;
          &:visited,
          &:hover,
          &:active{
            background: none;
            box-shadow: none;
          }
          &:hover{
            background-color: $black;
          }
        }
        .title{
          padding: 20px;
          font-size: 18px;
          margin: 0;
        }
        .price{
          padding: 20px;
          margin: 0;
        }
      }
      .cart_action{
        display: table-cell;
        vertical-align: middle;
      }
    }
  }
  
}
.payment-wrap{
  margin-top: 20px;
  .form-group{
    padding: 0 10px;
    label,input{
      font-family: $lato;
    }
  }
}
.inputs{
  border: .05rem solid #bcc3ce;
  background: #fff;
  padding: .4rem .4rem !important;
  &::placeholder{
    color: #fff !important;
  }
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}
button#submit{
  border: none;
  margin: 13px;
  margin-top: 20px;
}
.loader{
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(255,255,255,0.8);
  z-index: 11;
  .loading{
    margin-top: 50vh;
    transform: translateY(-100px) scale(2);
  }
}
/*cart*/

/*Chooser*/
.chooser{
  display:flex;
  height: 80vh;
  .left{
    background: $cream;
    height: 100%;
    flex:35%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    .header{
      margin: 5px 0;
      h4{
        color: $brown;
        font-size: 24px;
        span.ico{
          display: block;
          width: 50px;
          height: 50px;
          background: url('./icon.png') center center no-repeat;
          background-size: contain;
          margin: 15px auto;
        }
      }
    }
    .body{
      height: 65%;
      width: 100%;
    }
    .footer{
      display: flex;
      width: 100%;
      justify-content: space-between;
      a{
        display: inline-block;
        margin: 0 20px 20px 0;
        &.dim-btn{
          margin-left: 20px;
        }
      }
    }
  }
  .right{
    flex: 65%;
    height: 100%;
    background: $brown url('http://jjlalor-ie.stackstaging.com/wp-content/uploads/2020/10/header-bg.jpg') no-repeat bottom center;
    background-size: contain;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .showcase{
      height: 80%;
      width: calc(100% - 60px);
      display: flex;
      justify-content: center;
      margin: 30px;
      .preview-left,
      .preview-right{
        background: $green;
      }
      .preview-right{
        background: #fff;
        width: 50%;
        @media (max-width:768px) {
          width: 100%;
          background: #fff;
        }
        .bleed{
          padding: 10px;
          .image_crop{
            width: 120px;
            height: 156px;
            margin: auto;
            overflow: hidden;
            background: $gray url('./female.svg') no-repeat center center;
            background-size: cover;
            &.ellipse{
              border-radius: 100%;
            }
            &.ellipse_fade{
              box-shadow: inset 0px 0px 10px 10px rgba(255,255,255,1);
            }
            img{
              width: 100%;
              object-fit: cover;
            }
            
          }
          h1{
            font-size: 24px;
          }
          p{
            font-size: 12px;
          }
        }
        img{
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }
      @media(max-width: 768px){
        margin-top: 0;
      }
    }
    .text{
      align-self: center;
      p{
        margin-bottom: 0;
        font-weight: bold;
        font-size: 14px;
        color: #fff;
      }
    }
  }
  .left,
  .right{
    border: 1px solid lighten($brown,40%);
  }
}
/*Chooser*/
/*cover*/
.wrap-cover{
  max-height: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  
  ul{
    list-style: none;
    margin: 0;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    li{
      background-color: azure;
      margin: 5px;
      width: calc(25% - 10px);
      img{
        max-width: 100%;
        height: auto !important;
      }
    }
  }
  &::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
  }
  /* Optional: show position indicator in red */
  &::-webkit-scrollbar-thumb {
    background: #FF0000;
  }
}
/*cover*/
/*selector*/
.wrap-selector{
  display: table;
  height: 40vh;
  width: 100%;
  ul{
    list-style: none;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    li{
      width: 25%;
      text-align: center;
      display: inline-block;
      p{
        font-family: $lato;
      }
    }
    &.form{
      li{
        width: 80%;
        text-align: left;
        label{
          font-family: $lato;
        }
      }
    }
  }
  &.gender-selector{
    ul{
      display: flex;
      width: 100%;
      justify-content: space-around;
    }
  }
}
.caption{
  text-align: center;
  p{
    font-family: $lato;
  }
}
/*selector*/
/*Preview*/
.preview_wrapper {
  padding-top: 5%;
  .chooser{
    height: auto;
    .react-pdf__Page__textContent{
      display: none;
    }
  }
  
}
.title {
  text-align: center;
  .text.top{
    font-size: 24px;
    font-weight: 500;
    text-transform: uppercase;
    color: $brown;
  }
  h2{
    font-size: 55px;
    line-height: 70px;
    font-weight: 700;
  }
}

/*Preview*/
/*Sub Category*/
.listing{
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  li{
    margin-right: 5%;
    flex: calc(33.33% - 5%);
    flex-grow: 0;
    a{
      display: block;
      height: auto;
    }
    p{
      text-align: center;
      margin: 1rem;
    }
    img{
      margin: auto;
      width: 100%;
    }
    @media(max-width: 425px){
      flex: 100%;
    }
  }
}
/*Sub Category*/
/*Photo uploader*/
.photo-thumbnail{
  display: block;
  width: 109px;
  height: 142px;
  background-color: #fff;
  border: 1px dashed #000;
  line-height: 142px;
  text-align: center;
  margin: 20% auto;
  font-size: 12px;
  text-decoration: underline;
  font-family: $lato;
  font-weight: 700;
  cursor: pointer;
}
/*Template Loader*/
#template{
  margin-top: -9999999999px;
}
/*Template Loader*/
/*Off Nav*/
.off-nav{
  display: none;
  .off-canvas-toggle{
    display: block;
    left: auto;
    position: absolute;
    top: 21px;
    transition: none;
    z-index: 1;
    right: -5px;
    border: none;
    padding: 10px;
    padding-right: 0;
    color: $black;
    i{
      font-size: 30px;
      font-weight: 700;

    }
    &:visited,
    &:hover,
    &:active{
      color: $black;
      background: none;
      box-shadow: none;
      border: none;
    }
    &::after{
      display: none;
    }
    &:hover::after{
      display: none;
    }
  }
}
#sidebar-id .nav{
  flex-direction: column;
  li{
    .btn-link.disable{
      color: $black;
      text-transform: uppercase;
      font-family: $lato;
      font-size: 12px;
      font-weight: 700;
      &::after,
      &:hover::after{
        display: none;
      }
    }
    .nav{
      position: relative;
      padding-left: 20px;
    }
  }
}
.mobile-slider{
  display: none;
}
.wrap-selector-mobile{
  display: none;
}
/*Off Nav*/

/*photo uploader*/
/*tab*/
@media (max-width:1024px){
  .nav-desktop{
    display: none;
  }
  .off-nav{
    display: block;
  }
}
/*tab*/
/*mobile*/
@media (max-width:768px){
  .cart{
    margin-top: 20px;
  }
  .cart .cart-table .cart_table_row .details{
    flex-direction: column;
    .title{
      font-size: 13px;
      word-break: break-all;
    }
  }
  .cart .cart-table{
    max-width: 100%;
  }

  .wrap-selector-mobile{
    display: block;
    button.btn-primary{
      margin: 50px 10px;
      background-color: #fff;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      color: $black;
      font-size: 24px;
      &:focus{
        background-color: #fff;
        color: $black;
        box-shadow: none;
      }
    }
    .form{
      margin-left: 0;
      li{
        list-style: none;
      }
    } 
    
  }
  .wrap-selector.info{
    display: none;
  }
  .photo-thumbnail{
    margin: 0;
    width: 95px;
    height: 125px;
    line-height: 125px;
    margin-left: 10px;
  }
  .wrap-selector{
    height: 17vh;
    ul {
      
      margin-left: 0;
      li{
        display: flex;
        align-items: center;
        width: auto;
        img{
          width: 60px;
        }
        p{
          margin: 0;
          padding-left: 20px;
        }
      }
      
    }
  }
  .wrap-cover ul{
    display: none;
  }
  .logo h1{
    padding: 10px;
    margin-bottom: 0;
  }
  .off-nav .off-canvas-toggle{
    top: 12px;
    right: -9px;
  }
  .chooser{
    display: flex;
    height: auto;
    flex-direction: column-reverse;
    margin-top: 20px;
    .right{
      height: 80vh;
      .text p{
        padding-bottom: 10px;
        font-size: 12px;
      }
    }
    .left
    {
      align-items: flex-start;
     .header h4{
       margin: 10px 0;
       padding-left: 10px;
      span.ico{
        display: none;
      }
     } 
     .body{
       height: 12%;
       .caption{
         display: none;
       }
       .mobile-slider{
         display: block;
         ul{
           overflow: auto;
           white-space: nowrap;
           margin: 0;
           padding: 0;
           margin-left: 10px;
           li{
             list-style: none;
             background-color: burlywood;
             height: 143px;
             width: 96px;
             margin: 2px 5px 0 0;
             border: bisque solid 1px;
             display: inline-block;
             img{
               height: 100%;
               width: 100%;
             }
           }
         }
       }
     }
     .footer{
       justify-content: center;
       margin: 5px 0;
       a{
         margin: 0 10px;
         width: calc(50% - 10px);
         &.dim-btn{
           margin: 0;
         }
         &.main-btn{
           margin-right: 0;
         }
       }
     }
    }
  }
}

/*mobile*/


